<template>
  <ModalLayout
    v-model="isOpen"
    content-class="ReportDialog"
    :max-width="520"
    :persistent="selectFocused"
    :title="$t('report.DownloadReport')"
    @click:close="modalClose"
    @input="modalClose"
  >
    <div class="ReportDialog__body">
      <div
        v-if="!generation && !saving"
        class="ReportDialog__form"
      >
        <label
          class="ReportDialog__field"
          @click.prevent="$refs.templateSelect.open()"
        >
          {{ $t('report.ReportTemplate') }}
          <AppSelect
            ref="templateSelect"
            v-model="selectedTemplate"
            outlined
            :placeholder="$t('report.SelectTemplate')"
            dense
            :items="reportTemplates || []"
            item-value="uuid"
            item-text="fileName"
            return-object
            @focus="selectFocused = true"
            @blur="onSelectBlur"
          />
        </label>

        <label
          v-if="statuses != null"
          class="ReportDialog__field"
          @click.prevent="$refs.statusSelect.open()"
        >
          {{ $t('report.IssueStatus') }}
          <AppSelect
            ref="statusSelect"
            v-model="selectedStatusNames"
            multiple
            chips
            outlined
            dense
            :placeholder="$t('report.SelectStatus')"
            :items="statuses"
            item-value="name"
            item-text="displayName"
            :return-object="false"
            hide-details
            clearable
            clear-icon="mdi-close-circle"
            margins-with-hidden-details="mb-2"
            @focus="selectFocused = true"
            @blur="onSelectBlur"
          >
            <template #selection="{ item: status }">
              <v-chip
                class="ma-1"
                close
                small
                color="#F7F7FB"
                @click:close="rmStatusSelection(status.name)"
              >
                <v-icon
                  left
                  size="16"
                  :color="status.color"
                  v-text="status.icon"
                />
                {{ status.displayName }}
              </v-chip>
            </template>
          </AppSelect>
        </label>

        <v-tooltip
          v-if="currentUser && currentUser.isAdmin"
          bottom
          nudge-left="24"
        >
          <template #activator="{ on, attrs }">
            <div
              class="d-flex mb-12"
              v-bind="attrs"
              v-on="on"
            >
              <AppSwitch
                v-model="debug"
                :label="$t('report.Debug')"
                hide-details
                class="flex-grow-0"
              />
            </div>
          </template>

          <span v-text="$t('report.RenderDebugTagsM')" />
        </v-tooltip>
      </div>
      <div
        v-else
        class="ReportDialog__status"
      >
        <v-avatar
          class="ReportDialog__progress"
          color="white"
          :size="30"
        >
          <v-icon
            v-if="saving"
            color="success"
            v-text="'mdi-check-circle-outline'"
          />
          <v-progress-circular
            v-else
            :size="24"
            :width="2"
            color="success"
            :indeterminate="true"
          />
        </v-avatar>

        <div class="ReportDialog__text ml-2">
          <div class="ReportDialog__subtitle">
            {{ !saving && generation ? messages.generate : messages.saving }}
          </div>
          <div class="ReportDialog__message" />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="ReportDialog__footer">
        <div
          v-if="saving"
          class="ReportDialog__footer-alert"
        >
          <v-icon color="#FF9800">
            mdi-alert-outline
          </v-icon>
          <i18n
            path="report.SetupTocAndPaginationM"
            tag="div"
            class="ml-4 mr-4"
          >
            <br>
            {{ $t(isMacOs? 'Cmd': 'Ctrl') }}
          </i18n>
        </div>
        <v-btn
          v-if="!saving && !generation"
          class="ReportDialog__download-btn ml-auto"
          :disabled="selectedTemplate == null"
          color="primary"
          depressed
          @click="downloadReport"
        >
          {{ $t('report.Download') }}
        </v-btn>
        <v-btn
          v-if="!saving"
          :disabled="saving || generation"
          class="ReportDialog__cancel-btn ml-2"
          outlined
          color="primary"
          @click="modalClose"
        >
          {{ $t('report.Cancel') }}
        </v-btn>
        <v-btn
          v-else
          outlined
          color="primary"
          class="ml-2"
          @click="modalClose"
        >
          {{ $t('report.IGotIt') }}
        </v-btn>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'

export default {
  name: 'ReportDialog',

  components: {
    ModalLayout,
  },

  props: {
    value: { type: Boolean, default: true }, // v-model
    projectId: { type: String, required: true },
  },

  data() {
    return {
      isOpen: this.value,
      selectedTemplate: null,
      selectedStatusNames: [],
      debug: false,
      selectFocused: false,
      generation: false, // step waiting for template generation
      saving: false, // step waiting for template download
      messages: {
        generate: this.$t('report.GeneratingReport'),
        saving: this.$t('report.ReportDownloaded'),
      },
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
    reportTemplates() { return this.$store.getters['report/templates'] },
    statuses() { return this.$store.getters['$issueStatus/getList'](this.projectId) },
    isMacOs() { return navigator?.platform?.indexOf('Mac') !== -1 || false },
  },

  watch: {
    // v-model (:value + @input)
    value(isOpen) {
      this.isOpen = isOpen
      this.$store.commit('defaultLayout/setDisableGlobalShortcuts', isOpen)
    },
    isOpen: [
      function(isOpen) {
        if (isOpen !== this.value) this.$emit('input', isOpen)
        this.$store.commit('defaultLayout/setDisableGlobalShortcuts', isOpen)
      },
      {
        handler(isOpen) {
          if (isOpen) {
            this.loadData()
            this.resetStatusSelection()
          }
        },
        immediate: true,
      },
    ],

    reportTemplates: {
      handler(reportTemplates) {
        if (!reportTemplates?.length) {
          this.selectedTemplate = null
        } else if (
          !this.selectedTemplate ||
          !reportTemplates.some(t => t.uuid === this.selectedTemplate.uuid)
        ) this.selectedTemplate = reportTemplates[0]
      },
      immediate: true,
    },

    statuses: {
      immediate: true,
      handler(statuses) {
        if (statuses) this.resetStatusSelection()
      },
    },
  },

  methods: {
    modalClose() {
      this.isOpen = false
      setTimeout(() => {
        this.selectedTemplate = null
        this.generation = false
        this.saving = false
        this.loading = false
      }, 200)
    },

    loadData() {
      const { $store, projectId, statuses } = this
      this.loading = true
      $store.dispatch('report/getTemplates').finally(() => {
        this.loading = false
      })
      if (!statuses) $store.dispatch('$issueStatus/getList', { projectId })
    },

    resetStatusSelection() {
      const { statuses } = this
      if (statuses) {
        this.selectedStatusNames = statuses.map(status => status.name)
      }
    },

    async downloadReport() {
      const {
        $store,
        projectId,
        selectedTemplate: template,
        debug,
        selectedStatusNames,
      } = this
      if (!template) throw new Error('template is required')

      this.generation = true
      try {
        await $store.dispatch('report/downloadReport', {
          projectId,
          template,
          debug,
          selectedStatusNames,
        }).then(() => {
          this.saving = true
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.generation = false
      }
    },

    rmStatusSelection(statusName) {
      this.selectedStatusNames = this.selectedStatusNames
        .filter(sn => sn !== statusName)
    },

    onSelectBlur() {
      setTimeout(() => {
        this.selectFocused = false
      }, 100)
    },
  },
}
</script>

<style lang="sass">
.ReportDialog
  position: relative

  &__body
    width: 100%

  &__field
    font-size: 12px
    line-height: 20px
    letter-spacing: 0.005em
    color: rgba(0, 0, 0, 0.38) /* text/--disabled */

  &__status
    display: flex
    align-items: center
    padding: 50px 0

  &__footer
    display: flex
    justify-content: flex-end
    width: 100%

    &-alert
      display: flex
      align-items: flex-start
      font-size: 12px
      color: #666699
      margin-right: auto

  &__key
    background: #FAFAFA
    border: 1px solid #E6E6F2
    border-radius: 2px
    padding: 0 4px
</style>
